import SlickSlider from "react-slick"
import { Helmet } from "react-helmet"

export const Slider = ({ children, ...rest }) => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <SlickSlider {...rest}>{children}</SlickSlider>
    </>
  )
}
