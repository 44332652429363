import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

// Services
import services from "services"
import { updateToken } from "services/auth"

// Tracking
import analytics from "@mobi/libraries/analytics"

//Utils
import { getFromSessionStorage, saveOnSessionStorage } from "utils/storage"

//Data
import {
  dealFiltersDefaultValues,
  filtersSavedOnSessionStorage,
} from "./dataContent"

const useDeal = () => {
  const [isStepLoading, setIsStepLoading] = useState(true)
  const [isDealsLoading, setIsDealsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [nextFormStep, setNextFormStep] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedVehicle, selectVehicle] = useState({})
  const [stepData, setStepData] = useState({
    limit: 10,
    pages: 1,
    total: 1,
    count: 1,
    deals: [],
  })

  const filtersFormProps = useForm({
    defaultValues: dealFiltersDefaultValues,
  })

  const { next, prev } = useStep()
  const { setSimulation, simulation } = useContext(Context)

  const onSubmitFilter = (filters) => {
    setIsDealsLoading(true)

    saveFiltersOnSessionStorage(filters)
    saveVehicleDealPropertiesValues(filters)
    searchDeals(filters)
  }

  const saveFiltersOnSessionStorage = (filters) => {
    const mergedFilters = {
      ...filtersFormProps.getValues(),
      ...filters,
    }

    filtersSavedOnSessionStorage.forEach((filterKey) => {
      saveOnSessionStorage({
        name: filterKey,
        value: mergedFilters[filterKey],
      })
    })
  }

  const saveVehicleDealPropertiesValues = async (filters) => {
    const {
      city,
      state,
      brand,
      model,
      initialPrice,
      finalPrice,
      initialYear,
      finalYear,
    } = {
      ...filtersFormProps.getValues(),
      ...filters,
    }

    saveOnSessionStorage({
      name: "initialPrice",
      value: initialPrice,
    })
    saveOnSessionStorage({
      name: "finalPrice",
      value: finalPrice,
    })
    saveOnSessionStorage({
      name: "initialYear",
      value: initialYear,
    })
    saveOnSessionStorage({
      name: "finalYear",
      value: finalYear,
    })

    if (
      simulation.dealCity !== city ||
      simulation.brandId !== brand.value ||
      simulation.modelId !== (model?.value || 0)
    ) {
      const updatedSimulation = await services.vehicles
        .update(
          {
            ...simulation,
            brand:
              simulation.brandId !== brand.value
                ? brand
                : {
                    value: simulation.brandId,
                    label: simulation.brandName,
                  },
            model:
              simulation.modelId !== (model?.value || 0)
                ? model
                : {
                    value: simulation.modelId,
                    label: simulation.modelName,
                  },
            dealCity: simulation.dealCity !== city ? city : simulation.dealCity,
            dealState:
              simulation.dealState !== state ? state : simulation.dealState,
          },
          { sourceUrl: window?.location?.href }
        )
        .catch((err) => {
          console.log(err)
          setIsDealsLoading(false)
        })

      if (updatedSimulation?.status === 200) {
        setSimulation({
          ...simulation,
          brandId:
            simulation.brandId !== brand.value
              ? brand.value
              : simulation.brandId,
          brandName:
            simulation.brandName !== brand.label
              ? brand.label
              : simulation.brandName,
          modelId:
            simulation.modelId !== (model?.value || 0)
              ? model?.value
              : simulation.modelId,
          modelName:
            simulation.modelName !== model?.label
              ? model?.label
              : simulation.modelName,
          dealCity: simulation.dealCity !== city ? city : simulation.dealCity,
          dealState:
            simulation.dealState !== state ? state : simulation.dealState,
        })
      }
    }
  }

  const searchDeals = async (filters = {}, page) => {
    setIsDealsLoading(true)

    const finalCurrentPage = page ? page : currentPage
    if (page) {
      setCurrentPage(finalCurrentPage)
    }

    selectVehicle({})

    filters = { ...filtersFormProps.getValues(), ...filters }
    filtersFormProps.reset(filters)

    const stepData = await services.deal
      .getDeals({
        ...filters,
        brandId: filters.brand?.value,
        modelId: filters.model?.value,
      })
      .catch(() => {
        setIsStepLoading(false)
        setIsDealsLoading(false)
      })

    if (stepData?.deals) {
      setStepData(stepData)
      const filtersList = Object.entries(filters).map((entry) => entry)
    } else {
      setStepData({ ...stepData, deals: [] })
    }

    analytics.track("adsListView", {
      filters: {
        ...filters,
        brand: filters.brand?.label,
        model: filters.model?.label,
      },
      deals: stepData.deals || [],
      currentPage: finalCurrentPage,
    })

    setIsStepLoading(false)
    setIsDealsLoading(false)
  }

  const onPrev = () => {
    const newSimulationOnPrev = {
      ...simulation,
      nextFormStep: simulation.currentFormStep,
      currentFormStep: simulation.previousFormStep,
    }
    setSimulation(newSimulationOnPrev)
    prev(simulation?.previousFormStep)
  }

  const onNext = async (vehicle) => {
    setIsSubmitting(true)
    const newVehicle = selectedVehicle.dealId ? selectedVehicle : vehicle
    const updatedSimulation = await services.deal.update(newVehicle, {
      sourceUrl: window?.location?.href,
    })

    if (updatedSimulation.status === 200) {
      const data = updatedSimulation?.data
      const nextStep = data?.nextFormStep
      setNextFormStep(nextStep)
      if (data?.auth) {
        updateToken(data.auth)
      }
      setSimulation({
        ...data,
        currentFormStep: nextStep,
      })
      next(nextStep)
      analytics.track("vehiclesAds", vehicle)

      return data
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    async function getSimulation() {
      setIsStepLoading(true)

      const response = await services.deal.getSimulation().catch((err) => {
        setIsStepLoading(false)
        console.log(err)
      })

      const dealCity = response.dealCity || ""
      const dealState = response.dealState || ""

      const brandValue = {
        value: response.brandId,
        label: response.brandName,
      }
      const modelValue = response.modelId
        ? { value: response.modelId, label: response.modelName }
        : null

      if (response) {
        filtersFormProps.setValue("city", dealCity)
        filtersFormProps.setValue("state", dealState)
        filtersFormProps.setValue("newVehicles", !!response.isNewVehicle)

        const initialPrice =
          getFromSessionStorage({ name: "initialPrice" }) || ""
        const finalPrice = getFromSessionStorage({ name: "finalPrice" }) || ""
        const initialYear = getFromSessionStorage({ name: "initialYear" })
        const finalYear = getFromSessionStorage({ name: "finalYear" })

        const sessionStorageFilters = filtersSavedOnSessionStorage.reduce(
          (acc, filterKey) => {
            acc[filterKey] =
              getFromSessionStorage({ name: filterKey }) ||
              dealFiltersDefaultValues[filterKey]

            return acc
          },
          {}
        )

        filtersFormProps.reset({
          ...filtersFormProps.getValues(),
          ...{
            initialPrice,
            finalPrice,
            initialYear,
            finalYear,
            brandValue,
            modelValue,
          },
          ...sessionStorageFilters,
        })

        setSimulation({
          ...simulation,
          ...response,
          initialYear,
          finalYear,
          initialPrice,
          finalPrice,
        })

        searchDeals(
          {
            initialYear,
            finalYear,
            initialPrice,
            finalPrice,
            brand: brandValue,
            model: modelValue,
            city: dealCity,
            state: dealState || "",
          },
          1
        )
      }
    }

    getSimulation()
    analytics.track("pageLoad", {})
  }, [])

  return {
    isStepLoading,
    isDealsLoading,
    stepData,
    selectedVehicle,
    currentPage,
    nextFormStep,
    onNext,
    onPrev,
    selectVehicle,
    onSubmitFilter,
    searchDeals,
    setIsDealsLoading,
    filtersFormProps,
    isSubmitting,
  }
}

export default useDeal
