import isMobile from "@mobi/utils/browser/isMobile"
import browsers from "@mobi/utils/browser/browsers"
import isClient from "@mobi/utils/browser/isClient"

export const fillForm = ({ simulation = {}, formProps }) => {
  const hasSimulationData = Object.keys(simulation).length > 0
  if (hasSimulationData) {
    formProps.reset({})
    formProps.reset(simulation)
  }
}

export const fillField = ({ fieldName, value, formProps }) => {
  if (fieldName) {
    formProps.setValue(fieldName, value, {
      shouldValidate: true,
    })
  }
}

export const goToForm = () => {
  if (isClient()) {
    const field = document.querySelector("#ds-field-documentNumber")
    if (field) {
      if (isMobile) {
        field.scrollIntoView({ block: "center" })
      } else if (browsers.isSafari()) {
        window.scrollTo(0, 0)
      }

      field.focus()
    }
  }
}

export const getInputDescription = (rawAlias) => {
  const aliasMap = {
    documentNumber: "CPF",
    email: "E-mail",
    fullName: "Nome Completo",
    phone: "Celular",

    purchasePeriod: "Você já tem uma data para realizar esse sonho?",
    city: "Pesquisar em qual cidade? (Opcional)",

    downPayment: "Valor da entrada",
    numberOfInstallments: "Prazo",
    installments: "Parcelas",
  }

  return aliasMap[rawAlias]
}
