import { useCallback, useEffect, useState } from "react"

export function useAutoWidthCarousel({ containerSelector, gap }) {
  const [perView, setPerView] = useState(1)

  const onResize = useCallback(() => {
    const containerElement = document.querySelector(containerSelector)
    const containerWidth = containerElement.offsetWidth

    const carouselItems = Array.from(
      document.querySelectorAll(`${containerSelector} .slick-slide`)
    )

    const firstItemOfCurrentViewIndex =
      document.querySelector(`${containerSelector} .slick-active`)?.dataset
        ?.index || 0

    const { itemsPerView } = carouselItems.reduce(
      (acc, item, index) => {
        if (index < firstItemOfCurrentViewIndex) return acc

        let itemsPerView = acc.itemsPerView

        const currentItemGap = index === carouselItems.length - 1 ? 0 : gap
        const currentItemWidth = item.offsetWidth + currentItemGap

        const newTotalWidth = acc.totalWidth + currentItemWidth

        if (newTotalWidth <= containerWidth) {
          itemsPerView++
        }

        return {
          totalWidth: newTotalWidth,
          itemsPerView,
        }
      },
      { itemsPerView: 0, totalWidth: 0 }
    )

    setTimeout(() => setPerView(itemsPerView), 100)
  }, [containerSelector, gap])

  useEffect(() => {
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [onResize])

  return { perView, onResize }
}
