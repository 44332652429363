export const dealFiltersDefaultValues = {
  sort: "featured",
  limit: 9,
  city: "",
  state: "",
  kmRange: "50",
  minKm: "",
  maxKm: "",
  plateFinal: "",
  initialPrice: "",
  finalPrice: "",
  brand: null,
  model: null,
  initialYear: null,
  finalYear: null,
  newVehicles: false,
}

export const filtersSavedOnSessionStorage = [
  "sort",
  "kmRange",
  "minKm",
  "maxKm",
  "plateFinal",
  "newVehicles",
]
