import { VehiclesIcon } from "@garantidos/components"

import If from "@mobi/components/If"
//Components
import { Button } from "@mobi/ds"

import classNames from "classnames"

const Sender = ({
	onNext,
	onPrev,
	nextLabel = "Próximo",
	prevLabel = "Voltar",
	isNextDisabled,
	hasPrev,
	hasNext = true,
	className
}) => {
	return (
		<div className={classNames("sender", className)} style={{ marginTop: 30 }}>
			<If
				condition={hasNext}
				renderIf={
					<div className="sender__button">
						<Button
							style={{ marginBottom: 10 }}
							onClick={onNext}
							type="submit"
							disabled={isNextDisabled}
							fluid
							rightIcon={<VehiclesIcon id="icon-arrow-right" />}
							id="send-button"
						>
							{nextLabel}
						</Button>
					</div>
				}
			/>
			<If
				condition={hasPrev}
				renderIf={
					<Button
						id="back-button"
						variant="secondary"
						type="button"
						onClick={onPrev}
						fluid
					>
						{prevLabel}
					</Button>
				}
			/>
		</div>
	)
}

export default Sender
