// Components
import { VehiclesIcon } from "@garantidos/components"

import { Slider } from "../Slider"
// Hooks
import { useAutoWidthCarousel } from "./useAutoWidthCarousel"

import { Arrow } from "./components/Arrow"

export function AutoWidthCarousel({
	children,
	containerSelector,
	gap,
	arrows = true
}) {
	const { perView, onResize } = useAutoWidthCarousel({
		containerSelector,
		gap
	})

	var settings = {
		dots: false,
		arrows,
		infinite: false,
		slidesToShow: perView,
		slidesToScroll: 1,
		centerMode: false,
		variableWidth: true,
		onReInit: onResize,
		prevArrow: <Arrow icon={<VehiclesIcon id="icon-arrow-left" />} />,
		nextArrow: <Arrow icon={<VehiclesIcon id="icon-arrow-right" />} />
	}

	return <Slider {...settings}>{children}</Slider>
}
